import { inject, Injectable } from '@angular/core';
import { BroadcastChannelSender } from "../../helpers/broadcast-channel/BroadcastChannelSender.class";
import { AuthenticationToken, AuthenticationTokenMessage } from "./authentication.types";
import { IDBPDatabase, openDB } from "idb";
import { BehaviorSubject, Observable } from "rxjs";
import { ErrorPopupService } from "../../ui/popups/error-popup/services/error-popup.service";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
    private errorPopupService: ErrorPopupService = inject(ErrorPopupService);

    private readonly _dbName: string = 'RobocodeAuthentication';
    private readonly _storeName: string = 'tokens'
    private readonly _keyPath: string = 'url';
    private readonly _parentUrl: string;
    private readonly _subtractedTimeInSeconds: number = 600;

    private _dbVersion: number = 1;

    private _bearerTokenObserver: BehaviorSubject<string> = new BehaviorSubject<string>("");
    public isChecking: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor() {
        this._parentUrl = window.location.ancestorOrigins[0];
    }

    public getBearerTokenObserver(): Observable<string> {
        return this._bearerTokenObserver.asObservable();
    }

    private setBearerTokenObserver(token: string) {
        this._bearerTokenObserver.next(token);
        this.isChecking.next(false) ;
        sessionStorage.removeItem('db_updating');
    }

    public beginListenPostMessage(): this {
        window.addEventListener('message', this.messageHandler);
        return this;
    }

    private messageHandler = async (event: MessageEvent) => {
        if (this.isAuthenticationTokenMessage(event.data)) {
            await this.handleAuthenticationToken(this.buildAuthenticationToken(event.data));
        }
    };

    private isAuthenticationTokenMessage(data: any): data is AuthenticationTokenMessage {
        return typeof data === 'object' &&
            'expires_in' in data &&
            'token' in data;
    }

    private buildAuthenticationToken(message: AuthenticationTokenMessage): AuthenticationToken {
        return {
            token: message.token,
            expires: new Date().getTime() + (message.expires_in - this._subtractedTimeInSeconds) * 1000,
            url: this._parentUrl
        };
    }

    public async handleAuthenticationToken(tokenInfo: AuthenticationToken): Promise<void>;
    public async handleAuthenticationToken(): Promise<void>;
    public async handleAuthenticationToken(tokenInfo?: AuthenticationToken): Promise<void> {
        this.isChecking.next(true);
        if (!sessionStorage.getItem('db_updating')) {
            sessionStorage.setItem('db_updating', '');
        }
        let db = await this.openDb(this._dbName, this._dbVersion, this._storeName, this._keyPath);
        let transaction = db.transaction(this._storeName, "readwrite");
        let tokenStore = transaction.objectStore(this._storeName);
        try {
            let foundToken: AuthenticationToken = await tokenStore.get(this._parentUrl);
            if (foundToken) {
                 await this.handleTokenValidation(foundToken);
            } else {
                if (tokenInfo) {
                    await this.addToken(tokenStore, tokenInfo);
                } else {
                    this.requestToken();
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    private async openDb(dbName: string, dbVersion: number, storeName: string, keyPath?: string) {
        let self = this;
        let db: IDBPDatabase = await openDB(dbName, dbVersion, {
            upgrade(db: IDBPDatabase) {
                if (!db.objectStoreNames.contains(storeName)) {
                    db.createObjectStore(storeName, { keyPath })
                }
            },
            blocked() {
                db.close();
                self.errorPopupService.show('indexDb_old');
            },
        });
        return db;
    }

    private async handleTokenValidation(tokenInfo: AuthenticationToken): Promise<void> {
        if (this.isDateExpired(tokenInfo.expires)) {
            await this.refreshToken();
        } else {
            this.setBearerTokenObserver(tokenInfo.token);
        }
    }

    private async addToken<T extends { token: string }>(tokenStore: any, tokenItem: T) {
        await tokenStore.add(tokenItem);
        this.setBearerTokenObserver(tokenItem.token);
    }

    private requestToken() {
        BroadcastChannelSender.postMessage('request_authorization', window.name);
    }

    private isDateExpired(date: number): boolean {
        return date <= new Date().getTime();
    }

    public async refreshToken(): Promise<void> {
        this.isChecking.next(true);
        let db = await this.openDb(this._dbName, this._dbVersion, this._storeName, this._keyPath);
        let transaction = db.transaction(this._storeName, "readwrite");
        let tokenStore = transaction.objectStore(this._storeName);
        try {
            await tokenStore.delete(this._parentUrl);
            this.requestToken();
        } catch (error) {
            console.error(error);
        }
    }

    public async updateToken() {
        let db = await this.openDb(this._dbName, this._dbVersion, this._storeName, this._keyPath);
        let transaction = db.transaction(this._storeName, "readonly");
        let tokenStore = transaction.objectStore(this._storeName);
        try {
            let tokenInfo: AuthenticationToken = await tokenStore.get(this._parentUrl);
            this.setBearerTokenObserver(tokenInfo.token);
        } catch (error) {
            console.error(error);
        }
    }
}
